import { Box, Chip, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import Divider from '@mui/material/Divider';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';


import Typography from "@mui/material/Typography";
import { tokens } from "../../theme";

import { mockDataProjects } from "../../data/mockData";

const projects = mockDataProjects;

// "id": 12,
// "title": "Dashboard UI Project",
// "status": "early",
// "content": "Adipisicing eu magna velit est exercitation et consequat Lorem laboris nulla. Laborum exercitation minim id ea ea. Minim cillum magna excepteur laboris duis labore pariatur Lorem aute cupidatat velit sunt non. Est laborum anim aliqua in elit consequat elit elit cupidatat. Nulla excepteur laborum voluptate nisi eiusmod nostrud sit. Aute aliquip sit non consectetur laborum velit in exercitation laboris officia adipisicing deserunt. Sint laboris aute minim aliqua aute culpa laboris ad amet dolor ea Lorem sit.",
// "category": "Web Design",
// "rate": 5,
// "popular": 12,
// "percentage": 33

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" color="secondary" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

const BasicCard = ({ value }) => {
    const { id, title, status, category, content, percentage } = value;

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
      <Card sx={{ minWidth: 275 }} sx={{ borderRadius: '10px' }}>
        <CardContent>
          <Typography
                    variant="h3"
                    color={colors.grey[100]}
                    sx={{ m: "10px 0px 5px 0" }}
                  >{title}</Typography>
          <Typography variant="h5" color={colors.greenAccent[500]} sx={{ mb: "10px"}}>
          {category}
          </Typography>

          <Typography variant="h6" color={colors.greenAccent[700]} sx={{ mb: "0px"}}>
          Start date:
          </Typography>
          <Typography variant="h6" fontWeight="bold" color={colors.greenAccent[500]} sx={{ mb: "10px"}}>
          March 23, 2023
          </Typography>

          <LinearProgressWithLabel value={percentage} />
        </CardContent>
      </Card>
    );
  }


const Projects = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="Projects" subtitle={`${projects.length} Running Projects`} />

      <Grid container justifyContent="left" spacing={3}>

      {projects.length ? (
        projects.map((value) => {
          return (
            <Grid item key={value.id} xs={12} sm={6} md={4}>
                <BasicCard value={value} />
            </Grid>
          );
        }) 
        ) : (
        <Grid md={24}>
          <Card>
          <CardContent><Typography>Data Not Found!</Typography></CardContent>
          </Card>
        </Grid>
      )}
      </Grid>
    </Box>
  );
};

export default Projects;
