import { Box, Chip, IconButton, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import Divider from '@mui/material/Divider';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
import Grow from '@mui/material/Grow';
import Slide from '@mui/material/Slide';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

import Typography from "@mui/material/Typography";
import { tokens } from "../../theme";

import { mockBarData } from "../../data/mockData";
import BarChart from "../../components/BarChart";
import PieChart from "../../components/PieChart";
import LineChart from "../../components/LineChart"
import { ResponsiveBar } from '@nivo/bar'

const data = [
    {
      "country": "AD",
      "hot dog": 163,
      "hot dogColor": "hsl(226, 70%, 50%)",
      "burger": 163,
      "burgerColor": "hsl(48, 70%, 50%)",
      "sandwich": 115,
      "sandwichColor": "hsl(4, 70%, 50%)",
      "kebab": 129,
      "kebabColor": "hsl(37, 70%, 50%)",
      "fries": 132,
      "friesColor": "hsl(144, 70%, 50%)",
      "donut": 186,
      "donutColor": "hsl(216, 70%, 50%)"
    },
    {
      "country": "AE",
      "hot dog": 111,
      "hot dogColor": "hsl(278, 70%, 50%)",
      "burger": 22,
      "burgerColor": "hsl(65, 70%, 50%)",
      "sandwich": 148,
      "sandwichColor": "hsl(139, 70%, 50%)",
      "kebab": 159,
      "kebabColor": "hsl(189, 70%, 50%)",
      "fries": 111,
      "friesColor": "hsl(338, 70%, 50%)",
      "donut": 88,
      "donutColor": "hsl(314, 70%, 50%)"
    },
    {
      "country": "AF",
      "hot dog": 4,
      "hot dogColor": "hsl(240, 70%, 50%)",
      "burger": 72,
      "burgerColor": "hsl(314, 70%, 50%)",
      "sandwich": 25,
      "sandwichColor": "hsl(208, 70%, 50%)",
      "kebab": 47,
      "kebabColor": "hsl(281, 70%, 50%)",
      "fries": 163,
      "friesColor": "hsl(114, 70%, 50%)",
      "donut": 134,
      "donutColor": "hsl(162, 70%, 50%)"
    },
    {
      "country": "AG",
      "hot dog": 133,
      "hot dogColor": "hsl(177, 70%, 50%)",
      "burger": 114,
      "burgerColor": "hsl(148, 70%, 50%)",
      "sandwich": 28,
      "sandwichColor": "hsl(29, 70%, 50%)",
      "kebab": 197,
      "kebabColor": "hsl(205, 70%, 50%)",
      "fries": 117,
      "friesColor": "hsl(51, 70%, 50%)",
      "donut": 2,
      "donutColor": "hsl(168, 70%, 50%)"
    },
    {
      "country": "AI",
      "hot dog": 171,
      "hot dogColor": "hsl(134, 70%, 50%)",
      "burger": 178,
      "burgerColor": "hsl(105, 70%, 50%)",
      "sandwich": 189,
      "sandwichColor": "hsl(357, 70%, 50%)",
      "kebab": 33,
      "kebabColor": "hsl(48, 70%, 50%)",
      "fries": 124,
      "friesColor": "hsl(331, 70%, 50%)",
      "donut": 1,
      "donutColor": "hsl(296, 70%, 50%)"
    },
    {
      "country": "AL",
      "hot dog": 8,
      "hot dogColor": "hsl(322, 70%, 50%)",
      "burger": 69,
      "burgerColor": "hsl(46, 70%, 50%)",
      "sandwich": 97,
      "sandwichColor": "hsl(202, 70%, 50%)",
      "kebab": 27,
      "kebabColor": "hsl(109, 70%, 50%)",
      "fries": 66,
      "friesColor": "hsl(233, 70%, 50%)",
      "donut": 134,
      "donutColor": "hsl(60, 70%, 50%)"
    },
    {
      "country": "AM",
      "hot dog": 189,
      "hot dogColor": "hsl(29, 70%, 50%)",
      "burger": 86,
      "burgerColor": "hsl(47, 70%, 50%)",
      "sandwich": 176,
      "sandwichColor": "hsl(100, 70%, 50%)",
      "kebab": 13,
      "kebabColor": "hsl(307, 70%, 50%)",
      "fries": 84,
      "friesColor": "hsl(99, 70%, 50%)",
      "donut": 177,
      "donutColor": "hsl(316, 70%, 50%)"
    }
  ];

const MyResponsiveBar = ({ data /* see data tab */ }) => (
    <ResponsiveBar
        data={data}
        keys={[
            'hot dog',
            'burger',
            'sandwich',
            'kebab',
            'fries',
            'donut'
        ]}
        indexBy="country"
        margin={{ top: 10, right: 10, bottom: 50, left: 10 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'nivo' }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#eed312',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        fill={[
            {
                match: {
                    id: 'fries'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'sandwich'
                },
                id: 'lines'
            }
        ]}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'country',
            legendPosition: 'middle',
            legendOffset: 32
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'food',
            legendPosition: 'middle',
            legendOffset: -40
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={function (e){return e.id+": "+e.formattedValue+" in country: "+e.indexValue}}
    />
)

const charts = [ 
    {
    id: 1,
    title: 'Q1 2023 Sales Report',
    category: "Line",
},
{
    id: 2,
    title: 'Q4 2022 Sales Report',
    category: "Bar",
},
{
    id: 3,
    title: 'iPhone product Cost structure',
    category: "Pie",
},
{
    id: 4,
    title: 'Q3 2022 Sales Report',
    category: "Bar",
},
{
    id: 5,
    title: 'Income structure',
    category: "Pie",
},
{
    id: 6,
    title: 'Sales by Regions – 2022',
    category: "Line",
},
];

// "id": 12,
// "title": "Dashboard UI Project",
// "status": "early",
// "content": "Adipisicing eu magna velit est exercitation et consequat Lorem laboris nulla. Laborum exercitation minim id ea ea. Minim cillum magna excepteur laboris duis labore pariatur Lorem aute cupidatat velit sunt non. Est laborum anim aliqua in elit consequat elit elit cupidatat. Nulla excepteur laborum voluptate nisi eiusmod nostrud sit. Aute aliquip sit non consectetur laborum velit in exercitation laboris officia adipisicing deserunt. Sint laboris aute minim aliqua aute culpa laboris ad amet dolor ea Lorem sit.",
// "category": "Web Design",
// "rate": 5,
// "popular": 12,
// "percentage": 33

const ChartCard = ({ value }) => {
    const { id, title, status, category, content, percentage } = value;

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    let icon;
    switch(category) {
        case 'Bar':
            icon = <InsertChartOutlinedIcon />;
           break;
        case 'Pie':
            icon = <PieChartOutlineOutlinedIcon />;
            break;
        case 'Line':
            icon = <TimelineOutlinedIcon />;
            break;
        default:
            icon = <InsertChartOutlinedIcon />;
    }

    return (
      <>
        <Card sx={{ minWidth: 275, borderRadius: "10px" }}>
          <CardContent>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{ m: "10px 0px 5px 0" }}
            >
              <Box>
                <Typography variant="h3" color={colors.grey[100]}>
                  {title}
                </Typography>
              </Box>
              <Box>
                  <Chip
                    icon={icon}
                    label={category}
                  />
              </Box>
            </Box>
            <Typography
              variant="h6"
              color={colors.greenAccent[700]}
              sx={{ mb: "0px" }}
            >
              Created at:
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              color={colors.greenAccent[500]}
              sx={{ mb: "10px" }}
            >
              March 23, 2023
            </Typography>

            <Box width="90%" height="250px">
              {category === "Bar" && <BarChart isDashboard={true} />}
              {category === "Pie" && <PieChart isDashboard={true} />}
              {category === "Line" && <LineChart isDashboard={true} />}
            </Box>
          </CardContent>
          <CardHeader
            avatar={icon}
            action={
              <>
                <IconButton aria-label="share">
                  <ShareOutlinedIcon />
                </IconButton>
                <IconButton aria-label="more">
                    <MoreVertOutlinedIcon />
                </IconButton>
              </>
            }
            title={title}
            subheader="March 23, 2023"
          />
        </Card>
      </>
    );
  }


const Charts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="Charts" subtitle="Charts grid" />

      <Grid container justifyContent="left" spacing={3}>

      {charts.length ? (
        charts.map((value) => {
          return (
            <Grid item key={value.id} xs={12} sm={6} md={4}>
                <ChartCard value={value} />
            </Grid>
          );
        }) 
        ) : (
        <Grid md={24}>
          <Card>
          <CardContent><Typography>Data Not Found!</Typography></CardContent>
          </Card>
        </Grid>
      )}
      </Grid>
    </Box>
  );
};

export default Charts;
